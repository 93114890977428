import {RestLink} from 'apollo-link-rest';
import {ApolloClient, ApolloLink, InMemoryCache} from '@apollo/client';

let eInvoiceMobiClient = new ApolloClient({
    link: new RestLink({uri: `${process.env.REACT_APP_METHOD}://${process.env.REACT_APP_EINVOICE_USER}.${process.env.REACT_APP_EINVOICE_MOBI_URL}`}),
    cache: new InMemoryCache(),
});

export const mobiClient = () => {
    return eInvoiceMobiClient;
}

export const setMobiClient = (token) => {
    const authRestLink = new ApolloLink((operation, forward) => {
        operation.setContext(({headers}) => {
            return {
                headers: {
                    ...headers,
                    Accept: "application/json",
                    Authorization: `Bear ${token};${process.env.REACT_APP_EINVOICE_USER}`
                }
            };
        });

        return forward(operation).map(result => {
            return result;
        });
    });

    const restLink = new RestLink({uri: process.env.REACT_APP_EINVOICE_MOBI_URL});

    eInvoiceMobiClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: ApolloLink.from([authRestLink, restLink])
    });

    return eInvoiceMobiClient;
}
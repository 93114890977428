import {EINVOICE_TOKEN} from "../actions/einvoices.actions";

const initialEinvoice = {
    mobifone: "",
    vnpt: "",
    viettel: "",
};

const einvoiceReducer = (state = initialEinvoice, action) => {
    if (action.type === EINVOICE_TOKEN) {
        return {
            ...state,
            [action.name]: action.value
        }
    } else {
        return state;
    }
}

export default einvoiceReducer;
import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import PrivateRoute from './PrivateRoute'
import PageLoader from './components/Common/PageLoader';
import BasePage from './components/Templates/BasePage';
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "./store/actions/actions";
import {connect} from "react-redux";
import BaseHorizontal from "./components/Templates/BaseHorizontal";

const waitFor = (Tag) => (props) => <Tag {...props}/>;

/********************************
 * Các route của Hệ thống
 *********************************/
const SystemPageIndex = lazy(() => import('./components/Pages/System/Pages/Index'));
const SystemPageNotFound = lazy(() => import('./components/Pages/System/Pages/NotFound'));
const SystemPageError500 = lazy(() => import('./components/Pages/System/Pages/Error500'));
const SystemPageMaintenance = lazy(() => import('./components/Pages/System/Pages/Maintenance'));
const SystemVersionInfo = lazy(() => import('./components/Pages/System/Pages/VersionInfo'));
const SystemAccountProfile = lazy(() => import('./components/Pages/System/Account/Profile'));
const SystemAccountChangePassword = lazy(() => import('./components/Pages/System/Account/ChangePassword'));
const SystemSettingConfig = lazy(() => import('./components/Pages/System/Setting/Config'));
const SystemSettingBackup = lazy(() => import('./components/Pages/System/Setting/Backup'));
//const SystemPageStarter = lazy(() => import('./components/Pages/System/Setting/Starter'));
const PrivacyPolicy = lazy(() => import('./components/Pages/System/Pages/PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./components/Pages/System/Pages/TermsOfUse'));
const Donate = lazy(() => import('./components/Pages/System/Pages/Donate'));
const SubscribePlanExpired = lazy(() => import('./components/Pages/System/Pages/SubscribePlanExpired'));

const PrintTemplateList = lazy(() => import('./components/Pages/System/PrintTemplate/PrintTemplateList'));
const PrintTemplateCreate = lazy(() => import('./components/Pages/System/PrintTemplate/PrintTemplateCreate'));
const PrintTemplateUpdate = lazy(() => import('./components/Pages/System/PrintTemplate/PrintTemplateUpdate'));
const PrintTemplateDetail = lazy(() => import('./components/Pages/System/PrintTemplate/PrintTemplateDetail'));

const AuthLogin = lazy(() => import('./components/Pages/Auth/Login'));
const AuthRecover = lazy(() => import('./components/Pages/Auth/Recover'));
const AuthResetPassword = lazy(() => import('./components/Pages/Auth/ResetPassword'));
const AuthRegister = lazy(() => import('./components/Pages/Auth/Register'));

// const DashboardIndex = lazy(() => import('./components/Dashboard/Index'));
const DashboardSystem = lazy(() => import('./components/Pages/Dashboard/System'));
const DashboardManager = lazy(() => import('./components/Pages/Dashboard/Manager'));
const DashboardCashier = lazy(() => import('./components/Pages/Dashboard/Cashier'));
const DashboardWaiter = lazy(() => import('./components/Pages/Dashboard/Waiter'));

const SystemDashboard = lazy(() => import('./components/Pages/System/Dashboard/SystemDashboard'));
const POSDashboard = lazy(() => import('./components/Pages/POS/Dashboard/POSDashboard'));
const InventoryDashboard = lazy(() => import('./components/Pages/Inventory/Dashboard/InventoryDashboard'));
const ReportDashboard = lazy(() => import('./components/Pages/Report/Dashboard/ReportDashboard'));
/*const PurchaseDashboard = lazy(() => import('./components/Pages/Purchase/Dashboard/PurchaseDashboard'));*/

const InventoryUnitList = lazy(() => import('./components/Pages/Inventory/Unit/UnitList'));

const InventoryAttributeList = lazy(() => import('./components/Pages/Inventory/Attribute/AttributeList'));
const InventoryAttributeCreate = lazy(() => import('./components/Pages/Inventory/Attribute/AttributeCreate'));
const InventoryAttributeUpdate = lazy(() => import('./components/Pages/Inventory/Attribute/AttributeUpdate'));
const InventoryAttributeDetail = lazy(() => import('./components/Pages/Inventory/Attribute/AttributeDetail'));

const InventoryBrandList = lazy(() => import('./components/Pages/Inventory/Brand/BrandList'));

const InventoryWarehouseList = lazy(() => import('./components/Pages/Inventory/Warehouse/WarehouseList'));

const InventoryProductCategoryList = lazy(() => import('./components/Pages/Inventory/ProductCategory/ProductCategoryList'));

const InventoryProductList = lazy(() => import('./components/Pages/Inventory/Product/ProductList'));
const InventoryProductImport = lazy(() => import('./components/Pages/Inventory/Product/ProductImport'));

const InventoryBarcodeList = lazy(() => import('./components/Pages/Inventory/Barcode/BarcodeList'));

const InventoryShelvesList = lazy(() => import('./components/Pages/Inventory/Shelves/ShelvesList'));
const InventoryShelveCheck = lazy(() => import('./components/Pages/Inventory/Shelves/ShelveCheck'));

const InventoryConsignmentList = lazy(() => import('./components/Pages/Inventory/Consignment/ConsignmentList'));
const InventoryConsignmentCreate = lazy(() => import('./components/Pages/Inventory/Consignment/ConsignmentCreate'));
const InventoryConsignmentUpdate = lazy(() => import('./components/Pages/Inventory/Consignment/ConsignmentUpdate'));
const InventoryConsignmentDetail = lazy(() => import('./components/Pages/Inventory/Consignment/ConsignmentDetail'));


const InventoryAuditList = lazy(() => import('./components/Pages/Inventory/Audit/AuditList'));
const InventoryAuditCreate = lazy(() => import('./components/Pages/Inventory/Audit/AuditCreate'));
const InventoryAuditUpdate = lazy(() => import('./components/Pages/Inventory/Audit/AuditUpdate'));
const InventoryAuditDetail = lazy(() => import('./components/Pages/Inventory/Audit/AuditDetail'));

const StockList = lazy(() => import('./components/Pages/Inventory/Stocks/StocksList'));
const StockReceivingCreate = lazy(() => import('./components/Pages/Inventory/Stocks/Receiving/ReceivingCreate'));
const StockReceivingUpdate = lazy(() => import('./components/Pages/Inventory/Stocks/Receiving/ReceivingUpdate'));
const StockReceivingDetail = lazy(() => import('./components/Pages/Inventory/Stocks/Receiving/ReceivingDetail'));
const StockDeliveryCreate = lazy(() => import('./components/Pages/Inventory/Stocks/Delivery/DeliveryCreate'));
const StockDeliveryUpdate = lazy(() => import('./components/Pages/Inventory/Stocks/Delivery/DeliveryUpdate'));
const StockDeliveryDetail = lazy(() => import('./components/Pages/Inventory/Stocks/Delivery/DeliveryDetail'));
const StockTransferCreate = lazy(() => import('./components/Pages/Inventory/Stocks/Transfer/TransferCreate'));
const StockTransferUpdate = lazy(() => import('./components/Pages/Inventory/Stocks/Transfer/TransferUpdate'));
const StockTransferDetail = lazy(() => import('./components/Pages/Inventory/Stocks/Transfer/TransferDetail'));

const PoSIndex = lazy(() => import('./components/Pages/POS/Invoices/Index'));

const POSEInvoiceList = lazy(() => import('./components/Pages/POS/EInvoice/EInvoiceList'));

const POSSaleIndex = lazy(() => import('./components/Pages/POS/Sale/Index'));
const POSSaleList = lazy(() => import('./components/Pages/POS/Sale/SaleList'));
const POSSaleDefault = lazy(() => import('./components/Pages/POS/Sale/SaleDefault'));
const POSSaleAdvanced = lazy(() => import('./components/Pages/POS/Sale/SaleAdvanced'));
const POSSaleDetail = lazy(() => import('./components/Pages/POS/Sale/SaleDetail'));
const POSSaleAdvancedDetail = lazy(() => import('./components/Pages/POS/Sale/SaleAdvancedDetail'));
const POSSaleCounter = lazy(() => import("./components/Pages/POS/Sale/SaleCounter"));
const POSSalePrint = lazy(() => import('./components/Pages/POS/Sale/SalePrint'));

const POSQuotationList = lazy(() => import('./components/Pages/Quotation/QuotationsList'));
const POSQuotationCreate = lazy(() => import('./components/Pages/Quotation/QuotationCreate'));
const POSQuotationUpdate = lazy(() => import('./components/Pages/Quotation/QuotationCreate'));
const POSQuotationDetail = lazy(() => import('./components/Pages/Quotation/QuotationCreate'));

const POSTableList = lazy(() => import('./components/Pages/POS/Table/TableList'));

const POSCounterList = lazy(() => import('./components/Pages/POS/Counter/CounterList'));
const POSCounterCreate = lazy(() => import('./components/Pages/POS/Counter/CounterCreate'));
const POSCounterUpdate = lazy(() => import('./components/Pages/POS/Counter/CounterUpdate'));
const POSCounterDetail = lazy(() => import('./components/Pages/POS/Counter/CounterDetail'));

const POSAreaList = lazy(() => import('./components/Pages/POS/Area/AreaList'));

const POSPriceBookList = lazy(() => import('./components/Pages/POS/PriceBook/PriceBookList'));
const POSPriceBookCreate = lazy(() => import('./components/Pages/POS/PriceBook/PriceBookCreate'));
const POSPriceBookUpdate = lazy(() => import('./components/Pages/POS/PriceBook/PriceBookUpdate'));
const POSPriceBookDetail = lazy(() => import('./components/Pages/POS/PriceBook/PriceBookDetail'));

const ReportInventory = lazy(() => import('./components/Pages/Report/Inventory/InventoryReport'));
const ReportInventoryGeneral = lazy(() => import('./components/Pages/Report/Inventory/Inventory'));
const ReportInventoryBranch = lazy(() => import('./components/Pages/Report/Inventory/InventoryBranch'));
const ReportInventoryProduct = lazy(() => import('./components/Pages/Report/Inventory/InventoryProduct'));
const ReportInventorySupplier = lazy(() => import('./components/Pages/Report/Inventory/InventorySupplier'));
const ReportWarehouseCard = lazy(() => import('./components/Pages/Report/Inventory/WarehouseCard'));

const ReportSale = lazy(() => import('./components/Pages/Report/Sales/SaleReport'));
const ReportSaleGeneral = lazy(() => import('./components/Pages/Report/Sales/SaleGeneral'));
const ReportSaleCustomer = lazy(() => import('./components/Pages/Report/Sales/SaleCustomer'));
const ReportSaleStaff = lazy(() => import('./components/Pages/Report/Sales/SaleStaff'));
const ReportSaleProduct = lazy(() => import('./components/Pages/Report/Sales/SaleProduct'));
const ReportSaleProductSupplier = lazy(() => import('./components/Pages/Report/Sales/SaleProductSupplier'));
const ReportSaleCategoryProduct = lazy(() => import('./components/Pages/Report/Sales/SaleCategoryProduct'));
const ReportSaleBranch = lazy(() => import('./components/Pages/Report/Sales/SaleBranch'));
const ReportSaleBrand = lazy(() => import('./components/Pages/Report/Sales/SaleBrand'));
const ReportSaleArea = lazy(() => import('./components/Pages/Report/Sales/SaleArea'));
const ReportSaleTable = lazy(() => import('./components/Pages/Report/Sales/SaleTable'));

const ReportQuoteProduct = lazy(() => import('./components/Pages/Report/Quotation/QuoteProduct'));
const ReportQuoteCustomer = lazy(() => import('./components/Pages/Report/Quotation/QuoteCustomer'));
const ReportQuoteStaff = lazy(() => import('./components/Pages/Report/Quotation/QuoteStaff'));
const ReportQuoteCategoryProduct = lazy(() => import('./components/Pages/Report/Quotation/QuoteCategoryProduct'));

const ReportProduct = lazy(() => import('./components/Pages/Report/Product/Product'));
const ReportProductBestseller = lazy(() => import('./components/Pages/Report/Product/ProductBestseller'));
const ReportProductByDay = lazy(() => import('./components/Pages/Report/Product/ProductByDay'));

// Báo cáo tài chính
const ReportFinance = lazy(() => import('./components/Pages/Report/Finance/FinanceReport'));
const ReportFinanceCashBook = lazy(() => import('./components/Pages/Report/Finance/CashBookCash'));
const ReportFinanceCashBookBank = lazy(() => import('./components/Pages/Report/Finance/CashBookBank'));
const ReportFinanceCashier = lazy(() => import('./components/Pages/Report/Finance/Cashier'));

const ReportDebts = lazy(() => import('./components/Pages/Report/Debts/Debts'));

const Statistical = lazy(() => import('./components/Pages/Statistical/Index'));


/************************************
 Các Routes của HRM
 Nguyễn Minh Phúc
 27/10/2020
 Cập Nhật
 10/11/2020
 ************************************/

const HRMStaffList = lazy(() => import('./components/Pages/HRM/Staffs/StaffList'));
const HRMStaffImport = lazy(() => import('./components/Pages/HRM/Staffs/StaffImport'));
const HRMStaffReportSale = lazy(() => import('./components/Pages/Report/HRM/Staff/Staff'));
const HRMStaffReportSaleByProduct = lazy(() => import('./components/Pages/Report/HRM/Staff/ByProduct'));

const HRMDivisionList = lazy(() => import('./components/Pages/HRM/Divisions/DivisionList'));
// const HRMDivisionCreate = lazy(() => import('./components/Pages/HRM/Divisions/DivisionCreate'));
// const HRMDivisionUpdate = lazy(() => import('./components/Pages/HRM/Divisions/DivisionUpdate'));
// const HRMDivisionDetail = lazy(() => import('./components/Pages/HRM/Divisions/DivisionDetail'));

const HRMPositionList = lazy(() => import('./components/Pages/HRM/Positions/PositionList'));
// const HRMPositionCreate = lazy(() => import('./components/Pages/HRM/Positions/PositionCreate'));
// const HRMPositionUpdate = lazy(() => import('./components/Pages/HRM/Positions/PositionUpdate'));
// const HRMPositionDetail = lazy(() => import('./components/Pages/HRM/Positions/PositionDetail'));

const HRMWorkTypeList = lazy(() => import('./components/Pages/HRM/WorkType/WorkTypeList'));
// const HRMWorkTypeCreate = lazy(() => import('./components/Pages/HRM/WorkType/WorkTypeCreate'));
// const HRMWorkTypeUpdate = lazy(() => import('./components/Pages/HRM/WorkType/WorkTypeUpdate'));
// const HRMWorkTypeDetail = lazy(() => import('./components/Pages/HRM/WorkType/WorkTypeDetail'));

const HRMShiftList = lazy(() => import('./components/Pages/HRM/WorkHours/WorkHoursList'));
// const HRMShiftCreate = lazy(() => import('./components/Pages/HRM/WorkHours/WorkHoursCreate'));
// const HRMShiftUpdate = lazy(() => import('./components/Pages/HRM/WorkHours/WorkHoursUpdate'));
// const HRMShiftDetail = lazy(() => import('./components/Pages/HRM/WorkHours/WorkHoursDetail'));

const HRMTimeTracking = lazy(() => import('./components/Pages/HRM/TimeTracking/TimeTrackingTable'));

const HRMPayroll = lazy(() => import('./components/Pages/HRM/Payroll/PayrollList'));
const HRMPayrollCreate = lazy(() => import('./components/Pages/HRM/Payroll/PayrollCreate'));
const HRMPayrollUpdate = lazy(() => import('./components/Pages/HRM/Payroll/PayrollUpdate'));
const HRMPayrollDetail = lazy(() => import('./components/Pages/HRM/Payroll/PayrollDetail'));

const HRMLeaveList = lazy(() => import('./components/Pages/HRM/Leave/LeaveList'));
// const HRMLeaveCreate = lazy(() => import('./components/Pages/HRM/Leave/LeaveCreate'));
// const HRMLeaveUpdate = lazy(() => import('./components/Pages/HRM/Leave/LeaveUpdate'));
// const HRMLeaveDetail = lazy(() => import('./components/Pages/HRM/Leave/LeaveDetail'));

const HRMLeaveTypeList = lazy(() => import('./components/Pages/HRM/LeaveType/LeaveTypeList'));
// const HRMLeaveTypeCreate = lazy(() => import('./components/Pages/HRM/LeaveType/LeaveTypeCreate'));
// const HRMLeaveTypeUpdate = lazy(() => import('./components/Pages/HRM/LeaveType/LeaveTypeUpdate'));
// const HRMLeaveTypeDetail = lazy(() => import('./components/Pages/HRM/LeaveType/LeaveTypeDetail'));

const HRMAllowanceList = lazy(() => import('./components/Pages/HRM/Allowance/AllowanceList'));
// const HRMAllowanceCreate = lazy(() => import('./components/Pages/HRM/Allowance/AllowanceCreate'));
// const HRMAllowanceUpdate = lazy(() => import('./components/Pages/HRM/Allowance/AllowanceUpdate'));
// const HRMAllowanceDetail = lazy(() => import('./components/Pages/HRM/Allowance/AllowanceDetail'));

const HRMDeductionList = lazy(() => import('./components/Pages/HRM/Deduction/DeductionList'));
// const HRMDeductionCreate = lazy(() => import('./components/Pages/HRM/Deduction/DeductionCreate'));
// const HRMDeductionUpdate = lazy(() => import('./components/Pages/HRM/Deduction/DeductionUpdate'));
// const HRMDeductionDetail = lazy(() => import('./components/Pages/HRM/Deduction/DeductionDetail'));

const HRMCommissionList = lazy(() => import('./components/Pages/HRM/Commission/CommissionList'));

const HRMViolationPenaltyTypeList = lazy(() => import('./components/Pages/HRM/ViolationPenaltyType/ViolationPenaltyTypeList'));
// const HRMViolationPenaltyTypeCreate = lazy(() => import('./components/Pages/HRM/ViolationPenaltyType/ViolationPenaltyTypeCreate'));
// const HRMViolationPenaltyTypeUpdate = lazy(() => import('./components/Pages/HRM/ViolationPenaltyType/ViolationPenaltyTypeUpdate'));
// const HRMViolationPenaltyTypeDetail = lazy(() => import('./components/Pages/HRM/ViolationPenaltyType/ViolationPenaltyTypeDetail'));

const HRMConfigSalary = lazy(() => import('./components/Pages/HRM/ConfigSalary/ConfigSalary'));

const HRMDashboard = lazy(() => import('./components/Pages/HRM/Dashboard/HRMDashboard'));

/************************************
 Các Routes của CRM
 Nguyễn Minh Phúc
 20/12/2020
 ************************************/
const CRMSuppliersList = lazy(() => import('./components/Pages/CRM/Suppliers/SuppliersList'));
// const CRMSuppliersCreate = lazy(() => import('./components/Pages/CRM/Suppliers/SuppliersCreate'));
// const CRMSuppliersUpdate = lazy(() => import('./components/Pages/CRM/Suppliers/SuppliersUpdate'));
// const CRMSuppliersDetail = lazy(() => import('./components/Pages/CRM/Suppliers/SuppliersDetail'));
const CRMSuppliersImport = lazy(() => import('./components/Pages/CRM/Suppliers/SuppliersImport'));


const CRMSupplierGroupsList = lazy(() => import('./components/Pages/CRM/SupplierGroups/SupplierGroupsList'));

const CRMCustomersList = lazy(() => import('./components/Pages/CRM/Customers/CustomersList'));
const CRMCustomersImport = lazy(() => import('./components/Pages/CRM/Customers/CustomersImport'));

const CRMCustomerLevelList = lazy(() => import('./components/Pages/CRM/CustomerLevel/CustomerLevelList'));
const CRMCustomerLevelCreate = lazy(() => import('./components/Pages/CRM/CustomerLevel/CustomerLevelCreate'));
const CRMCustomerLevelUpdate = lazy(() => import('./components/Pages/CRM/CustomerLevel/CustomerLevelUpdate'));
const CRMCustomerLevelDetail = lazy(() => import('./components/Pages/CRM/CustomerLevel/CustomerLevelDetail'));

const CRMCustomerGroupsList = lazy(() => import('./components/Pages/CRM/CustomerGroups/CustomerGroupsList'));

const CRMDashboard = lazy(() => import('./components/Pages/CRM/Dashboard/CRMDashboard'));

/************************************
 Các Routes của Đặt hàng nhập
 tth.oanh
 11/07/2022
 ************************************/
const CRMStockOrderReceivingList = lazy(() => import('./components/Pages/CRM/StockOrderReceiving/OrderList'));
const CRMStockOrderReceivingCreate = lazy(() => import('./components/Pages/CRM/StockOrderReceiving/OrderCreate'));
const CRMStockOrderReceivingUpdate = lazy(() => import('./components/Pages/CRM/StockOrderReceiving/OrderUpdate'));
const CRMStockOrderReceivingDetail = lazy(() => import('./components/Pages/CRM/StockOrderReceiving/OrderDetail'));
const CRMStockOrderReceivingMail = lazy(() => import('./components/Pages/CRM/StockOrderReceiving/OrderMail'));
const CRMOrderMailDetail = lazy(() => import('./components/Pages/CRM/OrderMail/MailDetail'));

const SystemCompanyInfo = lazy(() => import('./components/Pages/System/Company/CompanyInfo'));

// EInvoices Router Configs
const SystemEInvoices = lazy(() => import('./components/Pages/System/EInvoices/'));

const SystemCompanySubscribePlans = lazy(() => import('./components/Pages/System/CompanySubscribePlan/CompanySubscribePlans'));

const SystemUserList = lazy(() => import('./components/Pages/System/Users/UserList'));

/*const SystemSubscriptionPlanList = lazy(() => import('./components/Pages/System/SubscriptionPlan/SubscriptionPlanList'));
const SystemSubscriptionPlanCreate = lazy(() => import('./components/Pages/System/SubscriptionPlan/SubscriptionPlanCreate'));
const SystemSubscriptionPlanUpdate = lazy(() => import('./components/Pages/System/SubscriptionPlan/SubscriptionPlanUpdate'));
const SystemSubscriptionPlanDetail = lazy(() => import('./components/Pages/System/SubscriptionPlan/SubscriptionPlanDetail'));*/

/*const SystemTypeOfBussinessList = lazy(() => import('./components/Pages/System/TypeOfBussiness/TypeOfBussinessList'));
const SystemTypeOfBussinessCreate = lazy(() => import('./components/Pages/System/TypeOfBussiness/TypeOfBussinessCreate'));
const SystemTypeOfBussinessUpdate = lazy(() => import('./components/Pages/System/TypeOfBussiness/TypeOfBussinessUpdate'));
const SystemTypeOfBussinessDetail = lazy(() => import('./components/Pages/System/TypeOfBussiness/TypeOfBussinessDetail'));*/

const SystemRoleList = lazy(() => import('./components/Pages/System/Roles/RoleList'));

const SystemLogEntriesList = lazy(() => import('./components/Pages/System/LogEntries/LogEntriesList'));

const SystemCloseBookList = lazy(() => import('./components/Pages/System/CloseBook/CloseBookList'));
const SystemCloseBookCreate = lazy(() => import('./components/Pages/System/CloseBook/CloseBookCreate'));

/*const SystemPrinterList = lazy(() => import('./components/Pages/System/Printer/PrinterList'));
const SystemPrinterCreate = lazy(() => import('./components/Pages/System/Printer/PrinterCreate'));
const SystemPrinterDetail = lazy(() => import('./components/Pages/System/Printer/PrinterDetail'));
const SystemPrinterUpdate = lazy(() => import('./components/Pages/System/Printer/PrinterUpdate'));*/


// Dashboard
const FinanceDashBoard = lazy(() => import('./components/Pages/Finance/Dashboard/FinanceDashboard'));
const FinanceCashbook = lazy(() => import('./components/Pages/Finance/CashBook/CashBookList'));
const FinanceBankBook = lazy(() => import('./components/Pages/Finance/CashBook/CashBookBank'));
const FinanceCashBookMoney = lazy(() => import('./components/Pages/Finance/CashBook/CashBookMoney'));

// Két tiền
const FinanceCashier = lazy(() => import('./components/Pages/Finance/Cashier/CashierList'));
const FinanceCreate = lazy(() => import('./components/Pages/Finance/Cashier/CashierCreate'));
const FinanceUpdate = lazy(() => import('./components/Pages/Finance/Cashier/CashierUpdate'));
const FinanceDetail = lazy(() => import('./components/Pages/Finance/Cashier/CashierDetail'));

// Quỹ đầu kỳ
const FinanceCashBookFund = lazy(() => import('./components/Pages/Finance/CashBook/CashBookFund'));

// Loại hình chi
const FinancePaymentsTypeList = lazy(() => import('./components/Pages/Finance/PaymentsType/PaymentsTypeList'));

// Loại hình thu
const FinanceReceiptsTypeList = lazy(() => import('./components/Pages/Finance/ReceiptsType/ReceiptsTypeList'));

// Người nhận/nộp khác
const FinanceObjectOtherList = lazy(() => import('./components/Pages/Finance/ObjectOther/ObjectOtherList'));
const FinanceObjectOtherCreate = lazy(() => import('./components/Pages/Finance/ObjectOther/ObjectOtherCreate'));
const FinanceObjectOtherUpdate = lazy(() => import('./components/Pages/Finance/ObjectOther/ObjectOtherUpdate'));
const FinanceObjectOtherDetail = lazy(() => import('./components/Pages/Finance/ObjectOther/ObjectOtherDetail'));

// Người nhận/nộp khác
const FinanceCofferList = lazy(() => import('./components/Pages/Finance/Coffer/CofferList'));


// Marketing
const MarketingDashboard = lazy(() => import('./components/Pages/Marketing/Dashboard/MarketingDashboard'));
const MarketingCampaignList = lazy(() => import('./components/Pages/Marketing/Campaign/CampaignList'));
// const MarketingCampaignCreate = lazy(() => import('./components/Pages/Marketing/Campaign/CampaignCreate'));
const MarketingCampaignDetail = lazy(() => import('./components/Pages/Marketing/Campaign/CampaignDetail'));
const MarketingCampaignUpdate = lazy(() => import('./components/Pages/Marketing/Campaign/CampaignUpdate'));

const MarketingVoucherList = lazy(() => import('./components/Pages/Marketing/Voucher/VoucherList'));
const MarketingVoucherCreate = lazy(() => import('./components/Pages/Marketing/Voucher/VoucherCreate'));
const MarketingVoucherDetail = lazy(() => import('./components/Pages/Marketing/Voucher/VoucherDetail'));
const MarketingVoucherUpdate = lazy(() => import('./components/Pages/Marketing/Voucher/VoucherUpdate'));

const MarketingCouponList = lazy(() => import('./components/Pages/Marketing/Coupon/CouponList'));
const MarketingCouponCreate = lazy(() => import('./components/Pages/Marketing/Coupon/CouponCreate'));
const MarketingCouponDetail = lazy(() => import('./components/Pages/Marketing/Coupon/CouponDetail'));
const MarketingCouponUpdate = lazy(() => import('./components/Pages/Marketing/Coupon/CouponUpdate'));

const MarketingAccumulationList = lazy(() => import('./components/Pages/Marketing/Accumulation/AccumulationList'));
const MarketingAccumulationCreate = lazy(() => import('./components/Pages/Marketing/Accumulation/AccumulationCreate'));
const MarketingAccumulationDetail = lazy(() => import('./components/Pages/Marketing/Accumulation/AccumulationDetail'));
const MarketingAccumulationUpdate = lazy(() => import('./components/Pages/Marketing/Accumulation/AccumulationUpdate'));

const MarketingBonusPointSetting = lazy(() => import('./components/Pages/Marketing/Point/PointSetting'));
const MarketingBonusPointTransaction = lazy(() => import('./components/Pages/Marketing/Point/PointList'));


// Asset
const AssetDashboard = lazy(() => import('./components/Pages/Asset/Dashboard/AssetDashboard'));
const AssetBookList = lazy(() => import('./components/Pages/Asset/AssetBook/AssetBookList'));
const AssetTypeList = lazy(() => import('./components/Pages/Asset/AssetType/AssetTypeList'));
const AssetTypeCreate = lazy(() => import('./components/Pages/Asset/AssetType/AsssetTypeCreate'));
const AssetTypeUpdate = lazy(() => import('./components/Pages/Asset/AssetType/AssetTypeUpdate'));
const AssetTypeDetail = lazy(() => import('./components/Pages/Asset/AssetType/AssetTypeDetail'));


// Ecommerce
const EcommerceDashboard = lazy(() => import('./components/Pages/Ecommerce/Dashboard/EcommerceDashboard'));

const EcommerceOrderList = lazy(() => import('./components/Pages/Ecommerce/Orders/PageOrders'));
const EcommerceOrderCreate = lazy(() => import('./components/Pages/Ecommerce/Orders/PageOrderCreate'));
const EcommerceOrderUpdate = lazy(() => import('./components/Pages/Ecommerce/Orders/PageOrderUpdate'));
const EcommerceOrderDetail = lazy(() => import('./components/Pages/Ecommerce/Orders/PageOrderDetail'));

const EcommerceSliderList = lazy(() => import('./components/Pages/Ecommerce/Sliders/PageSliders'));

const EcommerceSliderCreate = lazy(() => import('./components/Pages/Ecommerce/Sliders/SlidersCreate'));
const EcommerceSliderDetail = lazy(() => import('./components/Pages/Ecommerce/Sliders/SlidersDetail'));
const EcommerceSliderUpdate = lazy(() => import('./components/Pages/Ecommerce/Sliders/SlidersUpdate'));


const EcommerceSubscribeList = lazy(() => import('./components/Pages/Ecommerce/Subscribes/PageSubscribes'));
const EcommerceSubscribeCreate = lazy(() => import('./components/Pages/Ecommerce/Subscribes/PageSubscribeCreate'));
const EcommerceSubscribeUpdate = lazy(() => import('./components/Pages/Ecommerce/Subscribes/PageSubscribeUpdate'));
const EcommerceSubscribeDetail = lazy(() => import('./components/Pages/Ecommerce/Subscribes/PageSubscribeDetail'));

const EcommercePageList = lazy(() => import('./components/Pages/Ecommerce/Pages/StaticPages'));
const EcommercePageCreate = lazy(() => import('./components/Pages/Ecommerce/Pages/PageCreate'));
const EcommercePageUpdate = lazy(() => import('./components/Pages/Ecommerce/Pages/PageUpdate'));
const EcommercePageDetail = lazy(() => import('./components/Pages/Ecommerce/Pages/PageDetail'));

const EcommerceFormContactList = lazy(() => import('./components/Pages/Ecommerce/FormContact/PageFormContact'));

const EcommerceBlogList = lazy(() => import('./components/Pages/Ecommerce/Blogs/PageBlogs'));
const EcommerceBlogCreate = lazy(() => import('./components/Pages/Ecommerce/Blogs/PageBlogCreate'));
const EcommerceBlogUpdate = lazy(() => import('./components/Pages/Ecommerce/Blogs/PageBlogUpdate'));
const EcommerceBlogDetail = lazy(() => import('./components/Pages/Ecommerce/Blogs/PageBlogDetail'));

const EcommerceBlogTagList = lazy(() => import('./components/Pages/Ecommerce/BlogTags/PageBlogTags'));
const EcommerceBlogTagCreate = lazy(() => import('./components/Pages/Ecommerce/BlogTags/PageBlogTagCreate'));
const EcommerceBlogTagUpdate = lazy(() => import('./components/Pages/Ecommerce/BlogTags/PageBlogTagUpdate'));
const EcommerceBlogTagDetail = lazy(() => import('./components/Pages/Ecommerce/BlogTags/PageBlogTagDetail'));

const EcommerceBlogCategoryList = lazy(() => import('./components/Pages/Ecommerce/BlogCategories/PageBlogCategories'));
const EcommerceBlogCategoryCreate = lazy(() => import('./components/Pages/Ecommerce/BlogCategories/PageBlogCategoryCreate'));
const EcommerceBlogCategoryUpdate = lazy(() => import('./components/Pages/Ecommerce/BlogCategories/PageBlogCategoryUpdate'));
const EcommerceBlogCategoryDetail = lazy(() => import('./components/Pages/Ecommerce/BlogCategories/PageBlogCategoryDetail'));

const EcommerceSetting = lazy(() => import('./components/Pages/Ecommerce/Setting/PageSetting'));

// Debts
const DebtDashboard = lazy(() => import('./components/Pages/Debts/DebtDashboard'));
const DebtList = lazy(() => import('./components/Pages/Debts/DebtsList'));

const PluginIndex = lazy(() => import('./components/Pages/System/Plugin/PluginIndex'));

// Danh sách route không sử dụng layout template
const listOfPages = [
    '/login',
    '/register',
    '/recover',
    '/reset',
    '/lock',
    '/privacy',
    '/terms',
    '/donate',
    '/notfound',
    '/error500',
    '/maintenance',
    '/starter',
    '/pos',
    '/pos/processing',
    '/pos/waiter',
    '/ban-hang',
];

// Danh sách route không sử dụng sau khi đã đăng nhập
const listOfPagesNotUse = [
    '/login',
    '/register',
    '/recover',
    '/reset',
    '/lock',
];


const Routes = ({location, user}) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const host = window.location.hostname;
    const shopname = host.split('.');
    const timeout = {enter: 500, exit: 500};

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    if (shopname.length <= 2) {
        location.pathname = '/register';
    } else {
        if (!user.isAuthenticated) {
            location.pathname = '/login';
        } else {
            if (listOfPagesNotUse.indexOf(location.pathname) > -1) {
                location.pathname = '/';
            }
        }
    }

    const animationName = 'rag-fadeIn';
    if (currentKey === "reset") {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route path="/reset/:uidb64/:token" component={waitFor(AuthResetPassword)} exact/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else if (currentKey === "mobile-print") {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route path="/mobile-print/:code" component={waitFor(POSSalePrint)} exact/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else if (listOfPages.indexOf(location.pathname) > -1) {
        return (
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        <Route path="/notfound" component={waitFor(SystemPageNotFound)}/>
                        <Route path="/error500" component={waitFor(SystemPageError500)}/>
                        <Route path="/maintenance" component={waitFor(SystemPageMaintenance)}/>
                        <Route path="/login" component={waitFor(AuthLogin)}/>
                        <Route path="/recover" component={waitFor(AuthRecover)}/>
                        <Route path="/register" component={waitFor(AuthRegister)}/>
                        <Route path="/privacy" component={waitFor(PrivacyPolicy)}/>
                        <Route path="/terms" component={waitFor(TermsOfUse)}/>
                        <Route path="/donate" component={waitFor(Donate)}/>

                        <PrivateRoute authed={user.isAuthenticated} path="/ban-hang" component={waitFor(PoSIndex)}
                                      exact/>

                        <PrivateRoute authed={user.isAuthenticated} path="/pos" component={waitFor(POSSaleIndex)}
                                      exact/>
                        <PrivateRoute authed={user.isAuthenticated} path="/pos/processing"
                                      component={waitFor(POSSaleCounter)} exact/>
                        <Redirect to="/notfound"/>
                    </Switch>
                </Suspense>
            </BasePage>
        )
    } else if (location.pathname !== '/') {
        return (
            <BaseHorizontal>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    {/*Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/dashboard/system"
                                                  component={waitFor(DashboardSystem)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/dashboard/manager"
                                                  component={waitFor(DashboardManager)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/dashboard/cashier"
                                                  component={waitFor(DashboardCashier)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/dashboard/waiter"
                                                  component={waitFor(DashboardWaiter)} exact/>

                                    {/*POS/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/dashboard"
                                                  component={waitFor(POSDashboard)} exact/>

                                    {/*System*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/account/profile"
                                                  component={waitFor(SystemAccountProfile)}/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/account/change_password"
                                                  component={waitFor(SystemAccountChangePassword)}/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/setting/config"
                                                  component={waitFor(SystemSettingConfig)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/backup"
                                                  component={waitFor(SystemSettingBackup)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/info"
                                                  component={waitFor(SystemVersionInfo)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/expired"
                                                  component={waitFor(SubscribePlanExpired)} exact/>

                                    {/*Inventory/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/dashboard"
                                                  component={waitFor(InventoryDashboard)} exact/>

                                    {/*Inventory/Unit*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/units"
                                                  component={waitFor(InventoryUnitList)} exact/>

                                    {/*Inventory/Attribute*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/attributes"
                                                  component={waitFor(InventoryAttributeList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/attribute/create"
                                                  component={waitFor(InventoryAttributeCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/attribute/create/:id"
                                                  component={waitFor(InventoryAttributeCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/attribute/update/:id"
                                                  component={waitFor(InventoryAttributeUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/attribute/detail/:id"
                                                  component={waitFor(InventoryAttributeDetail)} exact/>

                                    {/*Inventory/Brand*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/brands"
                                                  component={waitFor(InventoryBrandList)} exact/>

                                    {/*Inventory/Warehouse*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/warehouses"
                                                  component={waitFor(InventoryWarehouseList)} exact/>

                                    {/*Inventory/ProductCategory*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/product_categories"
                                                  component={waitFor(InventoryProductCategoryList)} exact/>

                                    {/*Inventory/Product*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/products"
                                                  component={waitFor(InventoryProductList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/product/import"
                                                  component={waitFor(InventoryProductImport)} exact/>

                                    {/*Inventory/Barcode*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/barcode"
                                                  component={waitFor(InventoryBarcodeList)} exact/>

                                    {/*Inventory/Shelves*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/shelves"
                                                  component={waitFor(InventoryShelvesList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/qr_check/:code"
                                                  component={waitFor(InventoryShelveCheck)} exact/>

                                    {/*Inventory/Consignment*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/consignments"
                                                  component={waitFor(InventoryConsignmentList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/consignment/create"
                                                  component={waitFor(InventoryConsignmentCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/consignment/create/:id"
                                                  component={waitFor(InventoryConsignmentCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/consignment/update/:id"
                                                  component={waitFor(InventoryConsignmentUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/consignment/detail/:id"
                                                  component={waitFor(InventoryConsignmentDetail)} exact/>

                                    {/*Inventory/Audit*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/audits"
                                                  component={waitFor(InventoryAuditList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/audit/create"
                                                  component={waitFor(InventoryAuditCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/audit/update/:id"
                                                  component={waitFor(InventoryAuditUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/inventory/audit/detail/:id"
                                                  component={waitFor(InventoryAuditDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/stocks"
                                                  component={waitFor(StockList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/stocks/receiving/create/:type"
                                                  component={waitFor(StockReceivingCreate)} exact/>
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/stocks/receiving/update/:type/:id" component={waitFor(StockReceivingUpdate)} exact/>*/}
                                    <PrivateRoute authed={user.isAuthenticated}
                                                  path="/stocks/receiving/detail/:type/:id"
                                                  component={waitFor(StockReceivingDetail)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/stocks/delivery/create/:type"
                                                  component={waitFor(StockDeliveryCreate)} exact/>
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/stocks/delivery/update/:type/:id" component={waitFor(StockDeliveryUpdate)} exact/>*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/stocks/delivery/detail/:type/:id"
                                                  component={waitFor(StockDeliveryDetail)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/stocks/transfer/create/:type"
                                                  component={waitFor(StockTransferCreate)} exact/>
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/stocks/transfer/update/:type/:id" component={waitFor(StockTransferUpdate)} exact/>*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/stocks/transfer/detail/:type/:id"
                                                  component={waitFor(StockTransferDetail)} exact/>

                                    {/*POS/Table*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/tables"
                                                  component={waitFor(POSTableList)} exact/>

                                    {/*POS/Quotation*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/quotations"
                                                  component={waitFor(POSQuotationList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/quotation/create"
                                                  component={waitFor(POSQuotationCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/quotation/update/:id"
                                                  component={waitFor(POSQuotationUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/quotation/detail/:id"
                                                  component={waitFor(POSQuotationDetail)} exact/>

                                    {/*POS/Area*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/areas"
                                                  component={waitFor(POSAreaList)} exact/>

                                    {/*POS/Counter*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/counters"
                                                  component={waitFor(POSCounterList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/counter/create"
                                                  component={waitFor(POSCounterCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/counter/create/:id"
                                                  component={waitFor(POSCounterCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/counter/update/:id"
                                                  component={waitFor(POSCounterUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/counter/detail/:id"
                                                  component={waitFor(POSCounterDetail)} exact/>

                                    {/*POS/PriceBook*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/price_books"
                                                  component={waitFor(POSPriceBookList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/price_book/create"
                                                  component={waitFor(POSPriceBookCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/price_book/create/:id"
                                                  component={waitFor(POSPriceBookCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/price_book/update/:id"
                                                  component={waitFor(POSPriceBookUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/price_book/detail/:id"
                                                  component={waitFor(POSPriceBookDetail)} exact/>

                                    {/*POS/EInvoice*/}
                                    <PrivateRoute authed={user.isAuthenticated}
                                                  path="/pos/einvoices"
                                                  component={waitFor(POSEInvoiceList)} exact/>

                                    {/*POS/Sales*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/sales"
                                                  component={waitFor(POSSaleList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/sale/detail/:id"
                                                  component={waitFor(POSSaleDetail)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/pos/sale_advanced/detail/:id"
                                                  component={waitFor(POSSaleAdvancedDetail)} exact/>

                                    {/*Report/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/dashboard"
                                                  component={waitFor(ReportDashboard)} exact/>

                                    {/*Report/Sale*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale"
                                                  component={waitFor(ReportSale)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/general"
                                                  component={waitFor(ReportSaleGeneral)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/customer"
                                                  component={waitFor(ReportSaleCustomer)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/product"
                                                  component={waitFor(ReportSaleProduct)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/product_supplier"
                                                  component={waitFor(ReportSaleProductSupplier)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/category_product"
                                                  component={waitFor(ReportSaleCategoryProduct)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/staff"
                                                  component={waitFor(ReportSaleStaff)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/branch"
                                                  component={waitFor(ReportSaleBranch)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/brand"
                                                  component={waitFor(ReportSaleBrand)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/area"
                                                  component={waitFor(ReportSaleArea)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/sale/table"
                                                  component={waitFor(ReportSaleTable)} exact/>

                                    {/*Report/Inventory*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/inventory"
                                                  component={waitFor(ReportInventory)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/inventory/dashboard"
                                                  component={waitFor(ReportInventoryGeneral)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/inventory/branch"
                                                  component={waitFor(ReportInventoryBranch)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/inventory/supplier"
                                                  component={waitFor(ReportInventorySupplier)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/inventory/product"
                                                  component={waitFor(ReportInventoryProduct)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/inventory/warehouse_card"
                                                  component={waitFor(ReportWarehouseCard)} exact/>

                                    {/*Report/Product*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/product/dashboard"
                                                  component={waitFor(ReportProduct)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/product/bestseller"
                                                  component={waitFor(ReportProductBestseller)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/product/day"
                                                  component={waitFor(ReportProductByDay)} exact/>

                                    {/*Report/Quotation*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/quotation/customer"
                                                  component={waitFor(ReportQuoteCustomer)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/quotation/staff"
                                                  component={waitFor(ReportQuoteStaff)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/quotation/product"
                                                  component={waitFor(ReportQuoteProduct)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/quotation/category"
                                                  component={waitFor(ReportQuoteCategoryProduct)} exact/>

                                    {/*Report/Finance*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/finance"
                                                  component={waitFor(ReportFinance)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/finance/cashbook/cash"
                                                  component={waitFor(ReportFinanceCashBook)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/finance/cashbook/bank"
                                                  component={waitFor(ReportFinanceCashBookBank)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/finance/cashier"
                                                  component={waitFor(ReportFinanceCashier)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/report/debts"
                                                  component={waitFor(ReportDebts)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/statistical"
                                                  component={waitFor(Statistical)} exact/>

                                    {/* HRM/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/dashboard"
                                                  component={waitFor(HRMDashboard)} exact/>

                                    {/* HRM/Posotion*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/positions"
                                                  component={waitFor(HRMPositionList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/position/create" component={waitFor(HRMPositionCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/position/create/:id" component={waitFor(HRMPositionCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/position/update/:id" component={waitFor(HRMPositionUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/position/:id" component={waitFor(HRMPositionDetail)} exact/> */}

                                    {/* HRM/Staffs*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/staffs"
                                                  component={waitFor(HRMStaffList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/staff/import"
                                                  component={waitFor(HRMStaffImport)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/hrm/staff/dashboard"
                                                  component={waitFor(HRMStaffReportSale)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/report/hrm/staff/by-product"
                                                  component={waitFor(HRMStaffReportSaleByProduct)} exact/>


                                    {/* HRM/Division*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/divisions"
                                                  component={waitFor(HRMDivisionList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/division/create" component={waitFor(HRMDivisionCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/division/create/:id" component={waitFor(HRMDivisionCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/division/update/:id" component={waitFor(HRMDivisionUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/division/:id" component={waitFor(HRMDivisionDetail)} exact/> */}

                                    {/* HRM/WorkType*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/worktypes"
                                                  component={waitFor(HRMWorkTypeList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/worktype/create" component={waitFor(HRMWorkTypeCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/worktype/create/:id" component={waitFor(HRMWorkTypeCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/worktype/update/:id" component={waitFor(HRMWorkTypeUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/worktype/:id" component={waitFor(HRMWorkTypeDetail)} exact/> */}

                                    {/* HRM/WorkHours*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/whs"
                                                  component={waitFor(HRMShiftList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/wh/create" component={waitFor(HRMShiftCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/wh/create/:id" component={waitFor(HRMShiftCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/wh/update/:id" component={waitFor(HRMShiftUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/wh/:id" component={waitFor(HRMShiftDetail)} exact/> */}

                                    {/* HRM/TimeTracking*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/timetrackings"
                                                  component={waitFor(HRMTimeTracking)} exact/>

                                    {/* HRM/Payroll*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/payroll"
                                                  component={waitFor(HRMPayroll)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/payroll/create"
                                                  component={waitFor(HRMPayrollCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/payroll/update/:id"
                                                  component={waitFor(HRMPayrollUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/payroll/:id"
                                                  component={waitFor(HRMPayrollDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave"
                                                  component={waitFor(HRMLeaveList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave/create" component={waitFor(HRMLeaveCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave/create/:id" component={waitFor(HRMLeaveCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave/update/:id" component={waitFor(HRMLeaveUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave/:id" component={waitFor(HRMLeaveDetail)} exact/> */}


                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave_type"
                                                  component={waitFor(HRMLeaveTypeList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave_type/create" component={waitFor(HRMLeaveTypeCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave_type/create/:id" component={waitFor(HRMLeaveTypeCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave_type/update/:id" component={waitFor(HRMLeaveTypeUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/leave_type/:id" component={waitFor(HRMLeaveTypeDetail)} exact/> */}


                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/allowances"
                                                  component={waitFor(HRMAllowanceList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/allowance/create" component={waitFor(HRMAllowanceCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/allowance/create/:id" component={waitFor(HRMAllowanceCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/allowance/update/:id" component={waitFor(HRMAllowanceUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/allowance/:id" component={waitFor(HRMAllowanceDetail)} exact/> */}


                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/deductions"
                                                  component={waitFor(HRMDeductionList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/deduction/create" component={waitFor(HRMDeductionCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/deduction/create/:id" component={waitFor(HRMDeductionCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/deduction/update/:id" component={waitFor(HRMDeductionUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/deduction/:id" component={waitFor(HRMDeductionDetail)} exact/> */}

                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/commissions"
                                                  component={waitFor(HRMCommissionList)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/violation_penalty_type"
                                                  component={waitFor(HRMViolationPenaltyTypeList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/violation_penalty_type/create" component={waitFor(HRMViolationPenaltyTypeCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/violation_penalty_type/create/:id" component={waitFor(HRMViolationPenaltyTypeCreate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/violation_penalty_type/update/:id" component={waitFor(HRMViolationPenaltyTypeUpdate)} exact/> */}
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/hrm/violation_penalty_type/:id" component={waitFor(HRMViolationPenaltyTypeDetail)} exact/> */}

                                    <PrivateRoute authed={user.isAuthenticated} path="/hrm/salary/config"
                                                  component={waitFor(HRMConfigSalary)} exact/>

                                    {/* CRM/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/dashboard"
                                                  component={waitFor(CRMDashboard)} exact/>

                                    {/* CRM/Suppliers*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/suppliers"
                                                  component={waitFor(CRMSuppliersList)} exact/>
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/crm/supplier/create"*/}
                                    {/*              component={waitFor(CRMSuppliersCreate)} exact/>*/}
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/crm/supplier/create/:id"*/}
                                    {/*              component={waitFor(CRMSuppliersCreate)} exact/>*/}
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/crm/supplier/update/:id"*/}
                                    {/*              component={waitFor(CRMSuppliersUpdate)} exact/>*/}
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/crm/supplier/:id(\d+)"*/}
                                    {/*              component={waitFor(CRMSuppliersDetail)} exact/>*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/supplier/imports"
                                                  component={waitFor(CRMSuppliersImport)} exact/>

                                    {/* CRM/SupplierGroups*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/suppliergroups"
                                                  component={waitFor(CRMSupplierGroupsList)} exact/>

                                    {/* CRM/Customers*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customers"
                                                  component={waitFor(CRMCustomersList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customer/imports"
                                                  component={waitFor(CRMCustomersImport)} exact/>

                                    {/* CRM/CustomerLevel*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customerlevels"
                                                  component={waitFor(CRMCustomerLevelList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customerlevel/create"
                                                  component={waitFor(CRMCustomerLevelCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customerlevel/create/:id"
                                                  component={waitFor(CRMCustomerLevelCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customerlevel/update/:id"
                                                  component={waitFor(CRMCustomerLevelUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customerlevel/detail/:id"
                                                  component={waitFor(CRMCustomerLevelDetail)} exact/>

                                    {/* CRM/CustomerGroups*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/customergroups"
                                                  component={waitFor(CRMCustomerGroupsList)} exact/>

                                    {/* CRM/StockOrderReceiving*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/orders"
                                                  component={waitFor(CRMStockOrderReceivingList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/order/create"
                                                  component={waitFor(CRMStockOrderReceivingCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/order/create/:id"
                                                  component={waitFor(CRMStockOrderReceivingCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/order/update/:id"
                                                  component={waitFor(CRMStockOrderReceivingUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/order/:id"
                                                  component={waitFor(CRMStockOrderReceivingDetail)} exact/>
                                    <Route path="/crm/order/:id/confirm/:branch_id/"
                                           component={waitFor(CRMStockOrderReceivingMail)} exact/>

                                    {/* CRM/OrderMail*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/crm/order/:id/orderMail/:mail_id"
                                                  component={waitFor(CRMOrderMailDetail)} exact/>


                                    {/* System/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/dashboard"
                                                  component={waitFor(SystemDashboard)} exact/>

                                    {/* System/Company*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/company/info"
                                                  component={waitFor(SystemCompanyInfo)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/company/subscribe-plan"
                                                  component={waitFor(SystemCompanySubscribePlans)} exact/>

                                    {/* System/EInvoices*/}

                                    <PrivateRoute authed={user.isAuthenticated}
                                                  path="/system/einvoices/"
                                                  component={waitFor(SystemEInvoices)} exact/>

                                    {/* System/User*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/users"
                                                  component={waitFor(SystemUserList)} exact/>

                                    {/* System/TypeOfBussiness*/}
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/system/tobs" component={waitFor(SystemTypeOfBussinessList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/tob/create" component={waitFor(SystemTypeOfBussinessCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/tob/update/:id" component={waitFor(SystemTypeOfBussinessUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/tob/:id" component={waitFor(SystemTypeOfBussinessDetail)} exact/>*/}

                                    {/* System/Subscription*/}
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/system/subscriptions" component={waitFor(SystemSubscriptionPlanList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/subscription/create" component={waitFor(SystemSubscriptionPlanCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/subscription/update/:id" component={waitFor(SystemSubscriptionPlanUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/subscription/:id" component={waitFor(SystemSubscriptionPlanDetail)} exact/>*/}


                                    {/* System/Roles*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/roles"
                                                  component={waitFor(SystemRoleList)} exact/>

                                    {/* System/LogEntries*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/logentries"
                                                  component={waitFor(SystemLogEntriesList)} exact/>

                                    {/* System/CloseBook*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/close_books"
                                                  component={waitFor(SystemCloseBookList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/close_book/:type"
                                                  component={waitFor(SystemCloseBookCreate)} exact/>

                                    {/* System/Printer*/}
                                    {/*<PrivateRoute authed={user.isAuthenticated} path="/system/printers" component={waitFor(SystemPrinterList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/printer/create" component={waitFor(SystemPrinterCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/printer/update/:id" component={waitFor(SystemPrinterUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/printer/detail/:id" component={waitFor(SystemPrinterDetail)} exact/>*/}

                                    {/* System/PrintTemplate*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/print_template"
                                                  component={waitFor(PrintTemplateList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/print_template/create"
                                                  component={waitFor(PrintTemplateCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/print_template/update/:id"
                                                  component={waitFor(PrintTemplateUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/system/print_template/detail/:id"
                                                  component={waitFor(PrintTemplateDetail)} exact/>

                                    {/* Finance/Dashboard*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/dashboard"
                                                  component={waitFor(FinanceDashBoard)} exact/>

                                    {/* Finance/Cashbook*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/cashbook"
                                                  component={waitFor(FinanceCashbook)} exact/>
                                    {/* Finance/BankBook*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/bankbook"
                                                  component={waitFor(FinanceBankBook)} exact/>
                                    {/* Finance/BankBookMoney*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/cash_book_money"
                                                  component={waitFor(FinanceCashBookMoney)} exact/>

                                    {/* Finance/Cashier*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/cashier"
                                                  component={waitFor(FinanceCashier)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/cashier/create/:type"
                                                  component={waitFor(FinanceCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/cashier/update/:id"
                                                  component={waitFor(FinanceUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/cashier/:id"
                                                  component={waitFor(FinanceDetail)} exact/>

                                    {/* Finance/FinanceCashBookFund*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/fund"
                                                  component={waitFor(FinanceCashBookFund)} exact/>

                                    {/* Finance/PaymentsType*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/payments_type"
                                                  component={waitFor(FinancePaymentsTypeList)} exact/>

                                    {/* Finance/ReceiptsType*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/receipts_type"
                                                  component={waitFor(FinanceReceiptsTypeList)} exact/>

                                    {/* Finance/PersonOther*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/person_other"
                                                  component={waitFor(FinanceObjectOtherList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/person_other/create"
                                                  component={waitFor(FinanceObjectOtherCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/person_other/create/:id"
                                                  component={waitFor(FinanceObjectOtherCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/person_other/update/:id"
                                                  component={waitFor(FinanceObjectOtherUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/person_other/:id"
                                                  component={waitFor(FinanceObjectOtherDetail)} exact/>

                                    {/* Finance/Coffer*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/finance/coffer"
                                                  component={waitFor(FinanceCofferList)} exact/>

                                    {/* Marketing/Campaign*/}
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/dashboard"
                                                  component={waitFor(MarketingDashboard)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/campaign"
                                                  component={waitFor(MarketingCampaignList)} exact/>
                                    {/* <PrivateRoute authed={user.isAuthenticated} path="/marketing/campaign/create" component={waitFor(MarketingCampaignCreate)} exact/> */}
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/campaign/update/:id"
                                                  component={waitFor(MarketingCampaignUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/campaign/:id"
                                                  component={waitFor(MarketingCampaignDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/voucher"
                                                  component={waitFor(MarketingVoucherList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/voucher/create"
                                                  component={waitFor(MarketingVoucherCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/voucher/update/:id"
                                                  component={waitFor(MarketingVoucherUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/voucher/:id"
                                                  component={waitFor(MarketingVoucherDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/coupon"
                                                  component={waitFor(MarketingCouponList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/coupon/create"
                                                  component={waitFor(MarketingCouponCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/coupon/update/:id"
                                                  component={waitFor(MarketingCouponUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/coupon/:id"
                                                  component={waitFor(MarketingCouponDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/accumulation"
                                                  component={waitFor(MarketingAccumulationList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/accumulation/create"
                                                  component={waitFor(MarketingAccumulationCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated}
                                                  path="/marketing/accumulation/update/:id"
                                                  component={waitFor(MarketingAccumulationUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/accumulation/:id"
                                                  component={waitFor(MarketingAccumulationDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/point/transaction"
                                                  component={waitFor(MarketingBonusPointTransaction)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/marketing/point/setting"
                                                  component={waitFor(MarketingBonusPointSetting)} exact/>


                                    <PrivateRoute authed={user.isAuthenticated} path="/asset/dashboard"
                                                  component={waitFor(AssetDashboard)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/asset/book"
                                                  component={waitFor(AssetBookList)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/asset/type"
                                                  component={waitFor(AssetTypeList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/asset/type/create"
                                                  component={waitFor(AssetTypeCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/asset/type/update/:id"
                                                  component={waitFor(AssetTypeUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/asset/type/detail/:id"
                                                  component={waitFor(AssetTypeDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/dashboard"
                                                  component={waitFor(EcommerceDashboard)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/orders"
                                                  component={waitFor(EcommerceOrderList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/order/create"
                                                  component={waitFor(EcommerceOrderCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/order/update/:id"
                                                  component={waitFor(EcommerceOrderUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/order/detail/:id"
                                                  component={waitFor(EcommerceOrderDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/sliders"
                                                  component={waitFor(EcommerceSliderList)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/sliders/create"
                                                  component={waitFor(EcommerceSliderCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/sliders/create/:id"
                                                  component={waitFor(EcommerceSliderCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/sliders/detail/:id"
                                                  component={waitFor(EcommerceSliderDetail)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/sliders/update/:id"
                                                  component={waitFor(EcommerceSliderUpdate)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/subscribes"
                                                  component={waitFor(EcommerceSubscribeList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/subscribe/create"
                                                  component={waitFor(EcommerceSubscribeCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/subscribe/update/:id"
                                                  component={waitFor(EcommerceSubscribeUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/subscribe/detail/:id"
                                                  component={waitFor(EcommerceSubscribeDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/form-contacts"
                                                  component={waitFor(EcommerceFormContactList)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/pages"
                                                  component={waitFor(EcommercePageList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/page/create"
                                                  component={waitFor(EcommercePageCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/page/update/:id"
                                                  component={waitFor(EcommercePageUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/page/detail/:id"
                                                  component={waitFor(EcommercePageDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blogs"
                                                  component={waitFor(EcommerceBlogList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog/create"
                                                  component={waitFor(EcommerceBlogCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog/update/:id"
                                                  component={waitFor(EcommerceBlogUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog/detail/:id"
                                                  component={waitFor(EcommerceBlogDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog-tags"
                                                  component={waitFor(EcommerceBlogTagList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog-tag/create"
                                                  component={waitFor(EcommerceBlogTagCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog-tag/update/:id"
                                                  component={waitFor(EcommerceBlogTagUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog-tag/detail/:id"
                                                  component={waitFor(EcommerceBlogTagDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog-categories"
                                                  component={waitFor(EcommerceBlogCategoryList)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/blog-category/create"
                                                  component={waitFor(EcommerceBlogCategoryCreate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated}
                                                  path="/ecommerce/blog-category/update/:id"
                                                  component={waitFor(EcommerceBlogCategoryUpdate)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated}
                                                  path="/ecommerce/blog-category/detail/:id"
                                                  component={waitFor(EcommerceBlogCategoryDetail)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/ecommerce/setting"
                                                  component={waitFor(EcommerceSetting)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/debts/dashboard"
                                                  component={waitFor(DebtDashboard)} exact/>
                                    <PrivateRoute authed={user.isAuthenticated} path="/debts"
                                                  component={waitFor(DebtList)} exact/>

                                    <PrivateRoute authed={user.isAuthenticated} path="/system/plugins"
                                                  component={waitFor(PluginIndex)} exact/>

                                    <Redirect to="/notfound"/>
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </BaseHorizontal>
        )
    } else {
        if (user.isAuthenticated) {
            return (
                <BaseHorizontal>
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <Suspense fallback={<PageLoader/>}>
                                <Switch location={location}>
                                    <PrivateRoute authed={user.isAuthenticated} path="/"
                                                  component={waitFor(SystemPageIndex)}/>
                                </Switch>
                            </Suspense>
                        </CSSTransition>
                    </TransitionGroup>
                </BaseHorizontal>
            )
        } else {
            return (
                <Redirect to="/login"/>
            )
        }
    }
};

Routes.propTypes = {
    actions: PropTypes.object,
    user: PropTypes.object
};

const mapStateToProps = state => ({user: state.user});
const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withRouter(Routes));

import {EINVOICE_TOKEN} from "../actions/einvoices.actions";

const einvoices = store => next => action => {
    let result = next(action)
    if (action.type === EINVOICE_TOKEN) {
        return (store.getState())
    }
    return result
}

export default einvoices;
import gpl from "graphql-tag";
import {
    eInvoicesDefaultFields,
    eInvoicesFieldSimple,
    eInvoicesPaginateFieldDefault
} from "../fragments/eInvoiceFragment";

export const DETAIL_PDF_E_INVOICE_MOBI = gpl`
query detailEInvoice($id: String) {
  detailEInvoice(id: $id) @rest(
    method: "GET",
    path: "/api/Invoice68/inHoadon?id={args.id}&type=PDF&inchuyendoi=false",
  ) {data}
}`

// Lấy thông tin chi tiết gói hóa đơn điện tử
export const E_INVOICE_QUERY = gpl`
    ${eInvoicesFieldSimple}
    query eInvoices(
        $companiesId: Int!
        $limit: Int
    ) {
        eInvoices(
            companiesId: $companiesId
            limit: $limit
        ) {
            ...eInvoicesFieldSimple,
            eInvoicesCompanies
            {
              companyId
              companyIdTaxNumber
            }
        }
    }
`;

// Lấy danh sách các gói hóa đơn điện tử
export const E_INVOICE_DETAILS_QUERY = gpl`
    ${eInvoicesDefaultFields}
    query eInvoicesDetail($eInvoicesId: Int){
        eInvoicesDetail(eInvoicesId : $eInvoicesId)
        {
            ...eInvoicesDefaultFields,
            eInvoicesCompanies
            {
              companyId
              companyIdTaxNumber
            }

        }
    }
`;

// Lấy danh sách phân trang gói hóa đơn điện tử
export const E_INVOICE_PAGINATE_QUERY = gpl`
${eInvoicesPaginateFieldDefault}
query eInvoicesPaginate(
    $branchId: Int
    $keyword: String
    $sort: String
    $order: String
    $enable: String
    $show: Int
    $page: Int
){
    eInvoicesPaginate(
        branchId: $branchId
        keyword: $keyword
        sort: $sort
        order: $order
        enable: $enable
        show: $show
        page: $page
    ) {
        page
        pages
        hasNext
        hasPrev
        numberRecords
        keyword
        objects{
            ...eInvoicesPaginateFieldDefault
        }
    }
}`;

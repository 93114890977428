import gpl from "graphql-tag";
import {eInvoicesDefaultFields} from "../fragments/eInvoiceFragment";

export const REFS_E_INVOICE_MOBI_MUTATION = gpl`
query dataReferences {
  dataReferences @rest(
    method: "GET",
    type: "[dataReferences]",
    path: "/api/System/GetDataReferencesByRefId?refId=RF00059",
  ) {
        qlmtke_id
        qlkhsdung_id
        lhdon
        hthuc
        khdon
        khhdon
        sdmau
        code
        nglap
        nlap
        loaikh
        tenbl
        loaibl
        solienbl
        sottbl
        htbl
        khmbl
        tinh_code
        mtttien
        permission_id
        qlkhsdung_id1
        wb_user_id
        value
        id
    }
}
`

export const LOGIN_E_INVOICE_MOBI_MUTATION = gpl`
query loginUser($username: String!, $password: String!) {
  loginUser(input: { username: $username, password: $password }) 
  @rest(
    method: "POST",
    path: "/api/Account/login",
    type: "LoginResponse",
    bodyKey: "input"
  ) {
    token
    refresh_token,
    ma_dvcs
    wb_user_id,
    noti_sso
    notes_sso
    is_link_sso
    }
}
`

export const INFO_E_INVOICE_MOBI_MUTATION = gpl`
query infoEInvoice {
  infoEInvoice
  @rest(
    method: "GET",
    path: "/api/Invoice68/GetById?id=b1987974-2c52-4aac-8615-a434948dbdb4",
    type: "infoEInvoice",
  ) {
    hdon_id
    }
}
`

export const CREATE_E_INVOICE_MOBI_MUTATION = gpl`
query createEInvoiceMobi($input: input) {
  createEInvoiceMobi(input: $input) 
  @rest(
    method: "POST",
    path: "/api/Invoice68/SaveAndSignHoadon78",
    type: "[createEInvoiceMobi]",
    bodyKey: "input"
  ) {
    ok
    data 
    {
        id
        hdon_id
        cctbao_id
        hdlket_id
        tthai
        tthdon
        khieu
        shdon
        tdlap
        dvtte
        tgia
        gchu
        tnmua
        mnmua
        ten
        mst
        dchi
        email
        sdtnmua
        stknmua
        htttoan
        stknban
        sbmat
        mdvi
        nglap
        nlap
        ngsua
        nsua
        tgtcthue
        tgtthue
        ttcktmai
        tgtttbso
        tgtttbchu
        dlqrcode
        sdhang
        shdon1
        mccqthue
        ngky
        nky
        signature
        hthdbtthe
        tdlhdbtthe
        khmshdbtthe
        khhdbtthe
        shdbtthe
        tgtphi
        tgtcthue0
        tgtthue0
        ttcktmai0
        tgtttbso0
        tgtcthue5
        tgtthue5
        ttcktmai5
        tgtttbso5
        tgtcthue10
        tgtthue10
        ttcktmai10
        tgtttbso10
        tgtcthuekct
        tgtthuekct
        ttcktmaikct
        tgtttbsokct
        tgtcthuekkk
        tgtthuekkk
        ttcktmaikkk
        tgtttbsokkk
        tgtphi0
        tgtphi5
        tgtphi10
        tgtphikct
        tgtphikkk
        lhdon
        lddnbo
        tnvchuyen
        ptvchuyen
        dckhoxuat
        dckhonhap
        tennguoinhanhang
        mstnguoinhanhang
        phongban
        veviec
        sohopdong
        hdon68_id_lk
        mtdiep_cqt
        mtdiep_gui
        tthdon_old
        hdon_id_old
        is_hdcma
        hdon_ghichu
        tthdon_original
        kygui_cqt
        hdktngay
        tnhban
        tnhmua
        hddckptquan
        sbke
        faxban
        webban
        sqdbants
        nqdbants
        cqqdbants
        htbants
        cmndmua
        hdvc
        hvtnxhang
        hdktso
        nbke
        ddvchden
        tgvchdtu
        tgvchdden
        sdtban
        tkcktmn
        tgtttbso_last
        mdvqhnsach_mua
        mdvqhnsach_ban
        stbao
        ntbao
        kmai
        tgtcthuek
        tgtthuek
        ttcktmaik
        tgtttbsok
        error_status
        issendmail
        docngoaitetv
        giamthuebanhang20
        tienthuegtgtgiam
        lhdclquan
        khmshdclquan
        khhdclquan
        shdclquan
        nlhdclquan
        tiletienthuegtgtgiam
        tgtkhac
        thdon
        qtich
        cnctru
        ngccmnd
        nccmnd
        ktnhap
        thang
        nam
        msbl_tncn
        tlkthu
        loaihd
        cmnd
        dvtinh
        hdtndinh
        hdckhau
        kdbhbbuoc
        ktnhapstr
        cnkctru
        tudongtinhtien
        tgtkcthue
        tthang
        dthang
        ttthanhtoan
        orderid
        signing
        fkey
    }
    lst_hdon_id_success
  }
}
`

export const LIST_E_INVOICE_MOBI_BY_TIME_MUTATION = gpl`
query ListEInvoiceMobi($input: input) {
  eInvoices(input: $input) 
  @rest(
    method: "POST",
    path: "/api/Invoice68/GetInvoiceFromdateTodate",
    type: "[eInvoices]",
    bodyKey: "input"
  ) {
        id
        hdon_id
        cctbao_id
        hdlket_id
        tthai
        tthdon
        khieu
        shdon
        tdlap
        dvtte
        tgia
        gchu
        tnmua
        mnmua
        ten
        mst
        dchi
        email
        sdtnmua
        stknmua
        htttoan
        stknban
        sbmat
        mdvi
        nglap
        nlap
        ngsua
        nsua
        tgtcthue
        tgtthue
        ttcktmai
        tgtttbso
        tgtttbchu
        dlqrcode
        sdhang
        shdon1
        mccqthue
        ngky
        nky
        signature
        hthdbtthe
        tdlhdbtthe
        khmshdbtthe
        khhdbtthe
        shdbtthe
        tgtphi
        tgtcthue0
        tgtthue0
        ttcktmai0
        tgtttbso0
        tgtcthue5
        tgtthue5
        ttcktmai5
        tgtttbso5
        tgtcthue10
        tgtthue10
        ttcktmai10
        tgtttbso10
        tgtcthuekct
        tgtthuekct
        ttcktmaikct
        tgtttbsokct
        tgtcthuekkk
        tgtthuekkk
        ttcktmaikkk
        tgtttbsokkk
        tgtphi0
        tgtphi5
        tgtphi10
        tgtphikct
        tgtphikkk
        lhdon
        lddnbo
        tnvchuyen
        ptvchuyen
        dckhoxuat
        dckhonhap
        tennguoinhanhang
        mstnguoinhanhang
        phongban
        veviec
        sohopdong
        hdon68_id_lk
        mtdiep_cqt
        mtdiep_gui
        tthdon_old
        hdon_id_old
        is_hdcma
        hdon_ghichu
        tthdon_original
        kygui_cqt
        hdktngay
        tnhban
        tnhmua
        hddckptquan
        sbke
        faxban
        webban
        sqdbants
        nqdbants
        cqqdbants
        htbants
        cmndmua
        hdvc
        hvtnxhang
        hdktso
        nbke
        ddvchden
        tgvchdtu
        tgvchdden
        sdtban
        tkcktmn
        tgtttbso_last
        mdvqhnsach_mua
        mdvqhnsach_ban
        stbao
        ntbao
        kmai
        tgtcthuek
        tgtthuek
        ttcktmaik
        tgtttbsok
        error_status
        issendmail
        docngoaitetv
        details
        hoadon68_khac
  }
}
`
import { useEffect, useMemo, useState } from "react";
import PouchDB from "pouchdb-browser";
import pouchdbFind from "pouchdb-find";
import pouchdbAuthentication from "pouchdb-authentication";
import WebSocketService from "../store/websocket";

// Import các plugin của PouchDB
PouchDB.plugin(pouchdbAuthentication);
PouchDB.plugin(pouchdbFind);
PouchDB.plugin(require("pouchdb-upsert"));

// Lấy thông tin từ localStorage (hoặc có thể sử dụng biến môi trường)
const couchdb_name = localStorage.getItem("couchdb_name");
const couchdb_user = localStorage.getItem("couchdb_user");
const couchdb_password = localStorage.getItem("couchdb_password");
//const couchdb_user = process.env.REACT_APP_COUCHDB_USER;
//const couchdb_password = process.env.REACT_APP_COUCHDB_PASSWORD;
const remoteUrl = `${process.env.REACT_APP_COUCHDB_URL}/${couchdb_name}`;
// const remoteUrl = `http://localhost:5984/${couchdb_name}`;

export const usePouchDB = () => {
  const [error, setError] = useState(false); // Trạng thái lỗi
  const [ready, setReady] = useState(false); // Trạng thái khởi tạo hoàn tất
  const [alive, setAlive] = useState(true); // Trạng thái phiên hoạt động

  // Khởi tạo database local và remote chỉ khi các biến đầu vào thay đổi
  const localDb = useMemo(() => new PouchDB(`${couchdb_name}`), [couchdb_name]);
  const remoteDb = useMemo(
    () => new PouchDB(remoteUrl, { skip_setup: true }),
    [remoteUrl],
  );

  // Cấu hình đăng nhập
  const loginOpts = useMemo(
    () => ({
      ajax: {
        headers: {
          Authorization:
            "Basic " + window.btoa(couchdb_user + ":" + couchdb_password),
        },
      },
    }),
    [couchdb_user, couchdb_password],
  );

  // Hàm đăng nhập CouchDB
  const login = async () => {
    try {
      console.log("Attempting to log in to CouchDB...");
      await remoteDb.logIn(couchdb_user, couchdb_password, loginOpts);
      console.log("Login successful!");
      setAlive(true);
      setError(false);
    } catch (err) {
      console.error("Login failed:", err);
      setError(true);
    }
  };

  // Kiểm tra trạng thái phiên và đăng nhập lại nếu cần
  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        await remoteDb.info(); // Kiểm tra kết nối
        setAlive(true);
        console.log("Session is still alive!");
      } catch (error) {
        console.warn("Session expired, attempting to re-login...");
        setAlive(false);
        await login(); // Đăng nhập lại nếu phiên hết hạn
      }
    }, 1800000); // Kiểm tra mỗi 30 phút

    return () => clearInterval(intervalId); // Dọn dẹp interval khi component unmount
  }, [remoteDb, login]);

  // Bắt đầu đồng bộ dữ liệu giữa local và remote
  useEffect(() => {
    // đăng nhập lần đầu
    login();
    const syncHandler = localDb
      .sync(remoteDb, {
        live: true, // Đồng bộ real-time
        retry: true, // Tự động thử lại khi có lỗi
        continuous: true,
      })
      .on("change", (info) => {
        console.log("Change detected during sync:", info);
        // Data sync
        WebSocketService.sendMessage({
          token: localStorage.getItem("token"),
          shopName: localStorage.getItem("company_code"),
          syncData: info,
        });
      })
      .on("paused", () => {
        console.log("Sync paused (connection lost or idle).");
      })
      .on("active", () => {
        console.log("Sync resumed.");
      })
      .on("denied", (err) => {
        console.error("Sync denied:", err);
        setError(true);
      })
      .on("error", (err) => {
        console.error("Sync error:", err);
        setError(true);
      });

    return () => {
      syncHandler.cancel(); // Hủy đồng bộ khi component unmount
    };
  }, [localDb, remoteDb]);

  // Kiểm tra trạng thái database sau khi khởi tạo
  useEffect(() => {
    let intervalId;
    remoteDb
      .info()
      .then(() => {
        setReady(true); // Đặt trạng thái khởi tạo hoàn tất
        console.log("Remote database is ready!");

        // Kiểm tra trạng thái định kỳ
        intervalId = setInterval(() => {
          remoteDb
            .info()
            .then(() => setAlive(true))
            .catch(() => setAlive(false));
        }, 15000); // Kiểm tra mỗi 15 giây
      })
      .catch((err) => {
        console.error("Failed to connect to remote database:", err);
        setError(true);
      });

    return () => clearInterval(intervalId); // Dọn dẹp interval
  }, [remoteDb]);

  // Trả về các trạng thái và database để sử dụng trong component
  return { db: localDb, ready, alive, error };
};

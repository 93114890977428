import {Avatar, Badge, Dropdown, Layout, Menu, Space} from "antd";
import React, {useEffect, useState} from "react";
import {
    AppstoreOutlined,
    BarChartOutlined,
    BellOutlined, DesktopOutlined,
    DownOutlined, FieldTimeOutlined, MenuFoldOutlined, RightOutlined,
    ShopOutlined, UserOutlined,
    WalletOutlined
} from "@ant-design/icons";
import {Link, withRouter} from "react-router-dom";
import Wrapper from "./Header.styles";
import {useTranslation} from "react-i18next";
import {Modal} from "reactstrap";
import ListBranchForStaff from "../Organisms/List/ListBranchForStaff";
import CashierCheck from "../Organisms/Form/Cashier/CashierCheck";
import {useMutation} from "@apollo/client";
import {CONFIG_UPDATE_MUTATION} from "../../api/configs/configMutation";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import * as actions from "../../store/actions/actions";
import {connect} from "react-redux";
import i18n, {languages, savedLanguage} from "../../i18n";

const { Header, Content, Sider } = Layout;

const MainHeader = (props) => {
    const { i18n, t } = useTranslation();
    const [current] = useState((props.router && props.router.pathname) ? props.router.pathname : '');
    const [modalChangeBranch, setModalChangeBranch] = useState(false);
    const [modalCashierCheck, setModalCashierCheck] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(savedLanguage);

    const itemsDynamic = [
        {
            key: 'miniSide',
            icon: <MenuFoldOutlined />
        },
        {
            label: t('navigation.horizontal.pos'),
            key: 'pos',
            icon: <DesktopOutlined />
        },
        /*{
            label: t('navigation.horizontal.branch'),
            key: 'branch',
            icon: <ShopOutlined />
        },*/
        {
            label: t('navigation.horizontal.statistical'),
            key: 'statistical',
            icon: <BarChartOutlined />
        },
        /*{
            label: t('navigation.horizontal.attendance'),
            key: 'attendance',
            icon: <FieldTimeOutlined />
        },*/
        {
            label: t('navigation.horizontal.open&close'),
            key: 'cashier',
            icon: <WalletOutlined />
        },
        {
            label: t('navigation.horizontal.quickLink'),
            key: 'tools',
            icon: <AppstoreOutlined />,
            children: [
                {
                    label: 'Hóa đơn',
                    key: 'invoice',
                    icon: <RightOutlined />
                },
                {
                    label: 'Kho hàng',
                    key: 'stock',
                    icon: <RightOutlined />
                },
                {
                    label: 'Kiểm kê',
                    key: 'audit',
                    icon: <RightOutlined />
                },
                {
                    label: 'Hàng hóa',
                    key: 'product',
                    icon: <RightOutlined />
                },
                {
                    label: 'Nhân sự',
                    key: 'staff',
                    icon: <RightOutlined />
                },
                {
                    label: 'Khách hàng',
                    key: 'customer',
                    icon: <RightOutlined />
                },
                {
                    label: 'Nhà cung cấp',
                    key: 'supplier',
                    icon: <RightOutlined />
                },
                {
                    label: 'Mã vạch',
                    key: 'barcode',
                    icon: <RightOutlined />
                },
                {
                    label: 'Thiết lập',
                    key: 'setting',
                    icon: <RightOutlined />
                },
            ]
        },
    ];

    const itemsProfile = [
        {
            label: (<a onClick={()=> profile()}>Hồ sơ</a>),
            key: 'profile',
        },
        {
            label: (<a onClick={()=> changePassword()}>Đổi mật khẩu</a>),
            key: 'change-password',
        },
        {
            label: (<a onClick={()=> logout()}>Đăng xuất</a>),
            key: 'log-out',
        },
    ];

    const itemLanguages = [
        {
            label: (<a onClick={()=>changeLanguage('en')}><Avatar size={18} gap src={'img/flags/en.png'}/> English</a>),
            key: 'english',
        },
        {
            label: (<a onClick={()=>changeLanguage('vi')}><Avatar size={18} gap src={'img/flags/vi.png'}/> Tiếng việt</a>),
            key: 'vietnamese',
        },
        {
            label: (<a onClick={()=>changeLanguage('jp')}><Avatar size={18} gap src={'img/flags/jp.png'}/> Japan</a>),
            key: 'japan',
        },
    ]

    const handleMenu = (e) => {
        switch (e.key ){
            case 'miniSide':
                let typeMenu = props.configs.UI_HOME_MENU_MODE
                if(typeMenu === 'TEXT'){
                    typeMenu = 'ICON'
                }else if(typeMenu === 'ICON'){
                    typeMenu = 'TEXT'
                }
                props.actions.changeConfigs("UI_HOME_MENU_MODE", typeMenu);
                updateConfig({
                    variables: {
                        configCode: "UI_HOME_MENU_MODE",
                        configValue: typeMenu
                    }
                }).then();
                break
            case 'pos':
                props.history.push('/ban-hang')
                // props.history.push('/pos')
                break
            case 'branch':
                toggleModalChangeBranch();
                break
            case 'statistical':
                props.history.push('/statistical')
                break
            case 'attendance':
                break
            case 'cashier':
                toggleModalCashierCheck();
                break
            case 'invoice':
                props.history.push('/pos/sales')
                break
            case 'stock':
                props.history.push('/stocks')
                break
            case 'audit':
                props.history.push('/inventory/audits')
                break
            case 'product':
                props.history.push('/inventory/products')
                break
            case 'staff':
                props.history.push('/hrm/staffs')
                break
            case 'customer':
                props.history.push('/crm/customers')
                break
            case 'supplier':
                props.history.push('/crm/suppliers')
                break
            case 'barcode':
                props.history.push('/inventory/barcode')
                break
            case 'setting':
                props.history.push('/system/setting/config')
                break
        }
    }

    const changeLanguage = (code) => {
        i18n.changeLanguage(code);
        localStorage.setItem('language', code);
        setCurrentLanguage(code);
    }

    const profile = () => {
        props.history.push('/system/account/profile');
    }

    const changePassword = () => {
        props.history.push('/system/account/change_password');
    }

    const toggleModalChangeBranch = () => {
        setModalChangeBranch(!modalChangeBranch);
    };

    const toggleModalCashierCheck = () => {
        setModalCashierCheck(!modalCashierCheck);
    };

    const logout = () => {
        localStorage.setItem('token', "");

        //reset trang thái cho lần đăng nhập tiếp theo sẽ lấy dữ liệu mới
        props.actions.changeSetting('isFirstLoad', true);

        props.actions.changeUserLogin("username", "");
        props.actions.changeUserLogin("exp", 0);
        props.actions.changeUserLogin("origIat", 0);
        props.actions.changeUserLogin("token", "");
        props.actions.changeUserLogin("refreshExpiresIn", 0);
        props.actions.changeUserLogin("isAuthenticated", false);
        props.actions.changeUserLogin("firstName", "");
        props.actions.changeUserLogin("lastName", "");
        props.actions.changeUserLogin("isSuperuser", false);
        props.actions.changeUserLogin("isStaff", false);
        props.actions.changeUserLogin("isActive", false);
        props.actions.changeUserLogin("email", "");
        props.actions.changeUserLogin("groups", []);
        props.actions.changeUserLogin("userPermissions", []);
        props.actions.changeUserLogin("lastLogin", null);
        props.actions.changeUserLogin("dateJoined", null);
        props.actions.changeUserLogin("staff", {});
        props.actions.changeUserLogin("branchActivate", {});
        props.actions.changeUserLogin("permissions", []);
        props.actions.changeUserLogin("branches", []);
        props.actions.changeUserLogin("company", {});
        props.actions.changeMenu("menus", []);

        props.history.push('/login');
    }

    const onError = () => {

    };

    const [updateConfig] = useMutation(CONFIG_UPDATE_MUTATION, {
        onError: onError,
        fetchPolicy: "no-cache",
    });

    useEffect(() => {


        return () => {

        }
    }, [])

    return(
        <Wrapper>
            <Header style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                    }}
            >
                <div className="ant-pro-top-nav-header light">
                    <div className={`ant-pro-top-nav-header-main ${props.configs.UI_HOME_MENU_MODE === 'ICON' && 'header-min'}`}>
                        <div className={`ant-pro-top-nav-header-main-left`}>
                            <div className="ant-pro-top-nav-header-logo">
                                <Link to={"/"}>
                                    <img src={"./logo.png"} alt="logo" width={45} height={45}/>
                                </Link>
                            </div>
                        </div>
                        <div className="ant-pro-top-nav-header-menu">
                            <Menu theme='light'
                                  onClick={handleMenu}
                                  selectedKeys={[current]}
                                  mode="horizontal"
                                  items={itemsDynamic}
                            />
                        </div>
                        <div className="ant-pro-right-content">
                            <div style={{paddingRight: 'unset'}}>
                                <div className="ant-pro-right-content-resize">
                                    <div className="ant-space ant-space-horizontal ant-space-align-center">
                                        {/*<Space className="notification">
                                            <Badge count={5}>
                                                <BellOutlined />
                                            </Badge>
                                        </Space>*/}
                                        <Space className="language">
                                            <a onClick={toggleModalChangeBranch}>
                                                <Space>
                                                    <Avatar icon={<ShopOutlined />} />
                                                    {(props.user && props.user.branchActivate) && props.user.branchActivate.branchName}
                                                </Space>
                                            </a>

                                            <Dropdown menu={{ items: itemLanguages }}>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        {/*{t('navigation.language')}*/}
                                                        <Avatar
                                                            size="small"
                                                            src={languages[currentLanguage]?.flag || "img/flags/vi.png"}
                                                            style={{
                                                                backgroundColor: '#e6f4ff',
                                                                borderColor: '#91caff',
                                                                cursor: 'pointer',
                                                            }}
                                                        />
                                                        <DownOutlined />
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        </Space>
                                        <Space className="profile">
                                            <Dropdown menu={{ items: itemsProfile }}>
                                                <a onClick={(e) => e.preventDefault()}>
                                                    <Space>
                                                        {(props.user && props.user.firstName) && props.user.firstName}

                                                        {(props.user && props.user.staff && props.user.staff.staffAvatar) ?
                                                            <Avatar size="small" src={`${process.env.REACT_APP_BASE_URL}${props.user.staff.staffAvatar}`} />:
                                                            <Avatar size="small" icon={<UserOutlined />} />
                                                        }
                                                    </Space>
                                                </a>
                                            </Dropdown>
                                        </Space>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Header>

            <Modal isOpen={modalChangeBranch} toggle={toggleModalChangeBranch} size={"lg"}>
                <ListBranchForStaff onChange={(data) => {
                    props.actions.changeUserLogin("branchActivate", data);

                    toggleModalChangeBranch();

                    window.location.reload(false);
                    //props.history.push('/');
                }}/>
            </Modal>

            <Modal isOpen={modalCashierCheck} toggle={toggleModalCashierCheck} size={"lg"} backdrop={"static"}>
                <CashierCheck
                    onCompleted={() => {
                        toggleModalCashierCheck();
                    }}
                    onCancel={toggleModalCashierCheck}
                    onGoHome={toggleModalCashierCheck}
                    fullWidth={true}
                    onDenied={() => {
                        toggleModalCashierCheck();
                    }}
                />
            </Modal>
        </Wrapper>
    )
}

MainHeader.propTypes = {
    actions: PropTypes.object,
    settings: PropTypes.object,
    configs: PropTypes.object,
    user: PropTypes.object,
    menus: PropTypes.object,
};

const mapStateToProps = state => ({ settings: state.settings, configs: state.configs, user: state.user,  menus: state.menus });
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MainHeader));
class WebSocketClient {
    static instance = null;
    socket = null;
    messageListeners = [];
    retryCount = 0;
    method = process.env.REACT_APP_BASE_WS_METHOD;
    host = process.env.REACT_APP_BASE_WS_HOST;
    token = localStorage.getItem('token');
    shopName = localStorage.getItem('company_code');

    // Singleton pattern để đảm bảo chỉ có một instance WebSocketClient
    static getInstance() {
        if (!WebSocketClient.instance) {
            WebSocketClient.instance = new WebSocketClient();
        }
        return WebSocketClient.instance;
    }

    // Kết nối WebSocket
    connect(url) {
        if (this.socket) return;
        this.socket = new WebSocket(
            `${this.method}://${this.host}/${this.shopName}/${url}/?token=${this.token}`
        );

        this.socket.onopen = (event) => {
            console.log('WebSocket connected');
            this.retryCount = 0;
        };

        this.socket.onmessage = (event) => {
            // console.log('Raw message from server:', event.data);
            const data = JSON.parse(event.data);
            if(event.data.token !== this.token) {
                this.messageListeners.forEach((callback) => callback(data));
            }
        };

        this.socket.onclose = () => {
            console.log('WebSocket disconnected');
            this.socket = null;
            this.retryConnection(url);
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket error:', error);
            this.socket?.close();
        };
    }

    // Thử kết nối lại khi bị ngắt
    retryConnection(url) {
        const delay = Math.min(1000 * 2 ** this.retryCount, 30000);
        setTimeout(() => {
            this.retryCount += 1;
            console.log(`Reconnecting... Attempt #${this.retryCount}`);
            this.connect(url);
        }, delay);
    }

    // Gửi tin nhắn qua WebSocket
    sendMessage(message) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.log("WebSocket is not connected.");
        }
    }

    // Thêm hàm lắng nghe tin nhắn từ server
    addMessageListener(callback) {
        this.messageListeners.push(callback);

        // Trả về hàm để xóa listener
        return () => {
            this.messageListeners = this.messageListeners.filter((listener) => listener !== callback);
        };
    }

    // Xóa hàm lắng nghe
    removeMessageListener(callback) {
        this.messageListeners = this.messageListeners.filter((listener) => listener !== callback);
    }

    // Đóng WebSocket
    close() {
        this.socket?.close();
        this.socket = null;
    }

    // Kiểm tra trạng thái kết nối WebSocket
    isWebSocketConnected() {
        return this.socket && this.socket.readyState === WebSocket.OPEN;
    }
}

export default WebSocketClient.getInstance();

import React from 'react';
import {Link} from "react-router-dom";
import { Layout } from 'antd';
const { Footer } = Layout;

const FooterDefault = () => {
    const year = new Date().getFullYear();
    return (
        <Footer style={{ textAlign: 'center' }}>
            <span>&copy; {year} - {process.env.REACT_APP_POWERED_BY ? process.env.REACT_APP_POWERED_BY : "Powered by Da.Tech"}
                <Link to={"/system/info"}> v0.9.0 </Link>
            </span>
        </Footer>
    );
};

export default FooterDefault;

import gpl from "graphql-tag";

export const eInvoicesDefaultFields = gpl`
  fragment eInvoicesDefaultFields on EInvoicesModel{
    eInvoicesId
    eInvoicesDate
    eInvoicesSupplier
    eInvoicesLink
    eInvoicesUser
    eInvoicesPass
    eInvoicesConnect
    eInvoicesStatus
    eInvoicesDefault
    eInvoicesEnable
  }
`;


export const eInvoicesFieldSimple = gpl`
  fragment eInvoicesFieldSimple on EInvoicesModel{
    eInvoicesId
    eInvoicesDate
    eInvoicesSupplier
    eInvoicesLink
    eInvoicesUser
    eInvoicesPass
    eInvoicesConnect
    eInvoicesStatus
    eInvoicesDefault
    eInvoicesEnable
  }
`;

export const eInvoicesPaginateFieldDefault = gpl`
  fragment eInvoicesPaginateFieldDefault on EInvoicesModel{
    eInvoicesId
    eInvoicesDate
    eInvoicesSupplier
    eInvoicesLink
    eInvoicesUser
    eInvoicesPass
    eInvoicesConnect
    eInvoicesStatus
    eInvoicesDefault
    eInvoicesEnable
    eInvoicesCreatedAt
    eInvoicesUserCreated{
        username
    }
  }
`;